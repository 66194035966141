/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'SourceSansPro-Regular';
  src: url('fonts/SourceSansPro-Regular.otf');
}

.font-sourceSansProRegular {
  font-family: 'SourceSansPro-Regular', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'SFUIText-Regular';
  src: url('fonts/BF396DF7D0C0-Regular.otf');
}

.font-SFUITextRegular {
  font-family: 'SFUIText-Regular', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

.App { /* Base font style */
  font-family: 'SourceSansPro-Regular', sans-serif;
  font-size: 15.2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.8500);
  background-color: #f6f6f6;
}

.App .baseFont * { /* Base font style override for UI framework components */
  font-family: 'SourceSansPro-Regular', sans-serif;
  font-size: 15.2px;
}

.App .appBg {
  background-color: #f6f6f6;
}

.App .cardBg {
  background-color: white;
}

.App .baseTextBg { /* Base text color as background */
  background-color: rgba(0, 0, 0, 0.8500);
}

.App .secondaryTextColor {
  color: rgba(0, 0, 0, 0.5000);
}

.App .secondaryTextBg {
  background-color: rgba(0, 0, 0, 0.5000);
}

.App .primaryTextColor {
  color: #bee9ee;
}

.App .primaryBg {
  background-color: #bee9ee;
}

.App .highlightTextColor {
  color: #75d5ff;
}

.App .highlightBg {
  background-color: #75d5ff;
}

@media (display-mode: standalone) {
  .App {
    user-select: none;
  }
}

/* Styles to customize the UI framework plugin (MUI, https://muicss.com) */
.mui-btn {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}
.mui-btn--primary {
    background-color: #bee9ee;
}
.mui-btn--primary:active, .mui-btn--primary:focus, .mui-btn--primary:hover {
    background-color: #c3ebf0;
}
.mui-btn--accent {
    background-color: #75d5ff;
}
.mui-btn--accent:active, .mui-btn--accent:focus, .mui-btn--accent:hover {
    background-color: #80d9ff;
}
.mui-textfield {  /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}
.mui-checkbox, .mui-radio {  /* Reset UI framework styling that affects layout */
    margin-top: 0;
    margin-bottom: 0;
}
.mui-select {  /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}
.mui-btn+.mui-btn {  /* Reset UI framework styling that affects layout */
    margin-left: 0;
}
.mui-btn--fab {  /* Reset UI framework styling that breaks compositing */
    z-index: auto;
}

@keyframes fadeIn {
    0%   { opacity: 0.0; }
    100% { opacity: 1.0; }
}
@keyframes slideIn_right {
    0%   { transform: translateX(100%); }
    100% { transform: translateX(0); }
}
@keyframes slideIn_bottom {
    0%   { transform: translateY(100%); }
    100% { transform: translateY(0); }
}

/* System font classes */

.App .systemFontRegular {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}
.App .systemFontBold {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
}
.App .systemFontItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-style: italic;
}
.App .systemFontBoldItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
  font-style: italic;
}

/* App-wide text styles specified in React Studio */

.App .actionFont {
  font-family: 'SourceSansPro-Regular', sans-serif;
  font-size: 14.2px;
  font-weight: 400;
}
.App .actionFont * {
  font-size: 14.2px;
}
.App .headlineFont {
  font-family: 'SourceSansPro-Regular', sans-serif;
  font-size: 19.0px;
  font-weight: 400;
}
.App .headlineFont * {
  font-size: 19.0px;
}
.App .navBar {
  background-color: #bee9ee;
  color: black;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  min-height: 50px;
  font-family: 'SourceSansPro-Semibold', sans-serif;
  font-size: 18px;
}

.App .navBar a {
  color: black;
}

.App .navBar .title {
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 4px;
}

.App .navBar .backBtn {
  position: absolute;
  padding: 4px;
  margin-left: 14px;
  cursor: pointer;
}

.App .navBar > div {
  margin-top: 7px;
}

@media (min-width: 568px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 768px) {
  .App .navBar {
    height: 75px;
    min-height: 75px;
  }
  .App .navBar > div {
    margin-top: 19px;
  }
}
@media (min-width: 1024px) {
  .App .navBar {
    height: 75px;
    min-height: 75px;
  }
  .App .navBar > div {
    margin-top: 19px;
  }
}
@media (min-width: 320px) {
  .mui-container {
    max-width: 320px;
  }
}
@media (min-width: 568px) {
  .mui-container {
    max-width: 568px;
  }
}
@media (min-width: 768px) {
  .mui-container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .mui-container {
    max-width: 1024px;
  }
}

.AppScreen {
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.App .background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App .layoutFlow {
  position: relative;
  width: 100%;
  pointer-events: none;
}
.App .layoutFlow:before {  /* Prevent margin collapse */
  content: "";
  display: table;
  clear: both;
}
.App .layoutFlow .flowRow {
  position: relative;
  width: 100%;
}

.App .screenFgContainer {
  /* Items in the foreground group within a screen are placed with fixed positioning */
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.App .foreground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App ul {
  margin: 0;
  padding: 0;
}
.App li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
}
.App .hasNestedComps {
  position: relative;
}
.App .hasNestedComps:after {
  content: "";
  display: table;
  clear: both;
}
.App .hasNestedComps .background {
  position: absolute;
}
.App .hasNestedComps .layoutFlow {
  position: relative;
}
.App .bottomAlignedContent {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.App .blockInputOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: auto;
  z-index: 99999999;
}

.App .marker {
  width: 0px;
  height: 0px;
}

.App .drawer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.App .drawerOpen {
  transform: translateX(0%);
  transition: 0.3s;
}

.App .drawerClosedLeft {
  transform: translateX(-100%);
  transition: 0.3s;
}

.App .drawerClosedRight {
  transform: translateX(100%);
  transition: 0.3s;
}

.App .drawerContent {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

.App .drawerContent > * {
  height: 100%;
}

/* This component has a fixed-size layout */
  .Slider {
    min-height: 316px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 1021px */
    height: 100%;
  }
  .Slider > .background > .containerMinHeight {
    min-height: 316px;
  }

@keyframes fadeIn_Slider_state0_elStelth {
  from { opacity: 0.0; }
  to   { opacity: 1.0; }
}
.Slider > .layoutFlow > .state0_elStelth {
  animation: 0.25s fadeIn_Slider_state0_elStelth;
}

  .Slider > .layoutFlow > .state0_elStelth {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Slider > .layoutFlow > .state0_elStelth > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .Slider {
    min-height: 316px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 1021px */
    height: 100%;
  }
  .Slider > .background > .containerMinHeight {
    min-height: 316px;
  }

@keyframes fadeIn_Slider_state1_elCannon {
  from { opacity: 0.0; }
  to   { opacity: 1.0; }
}
.Slider > .layoutFlow > .state1_elCannon {
  animation: 0.25s fadeIn_Slider_state1_elCannon;
}

  .Slider > .layoutFlow > .state1_elCannon {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Slider > .layoutFlow > .state1_elCannon > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .Slider {
    min-height: 316px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 1021px */
    height: 100%;
  }
  .Slider > .background > .containerMinHeight {
    min-height: 316px;
  }


  .Slider > .layoutFlow > .state2_elHeavy {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Slider > .layoutFlow > .state2_elHeavy > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .Slider {
    min-height: 316px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 1021px */
    height: 100%;
  }
  .Slider > .background > .containerMinHeight {
    min-height: 316px;
  }


  .Slider > .layoutFlow > .state3_elJets {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Slider > .layoutFlow > .state3_elJets > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .Carousel {
    min-height: 317px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 623px */
    height: 100%;
  }
  .Carousel > .background > .containerMinHeight {
    min-height: 317px;
  }

.Carousel .elSlider {
  overflow: hidden;
}

.Carousel .elSlider.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Carousel > .layoutFlow > .elSlider {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Carousel > .layoutFlow > .elSlider > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .Logonarrow {
    min-height: 77px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 255px */
    height: 100%;
  }
  .Logonarrow > .background > .containerMinHeight {
    min-height: 77px;
  }


  .Logonarrow > .layoutFlow > .elImage {
    position: relative;
    margin-top: -2px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Logonarrow > .layoutFlow > .elImage > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .Logowide {
    min-height: 317px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 621px */
    height: 100%;
  }
  .Logowide > .background > .containerMinHeight {
    min-height: 317px;
  }

.Logowide .elComp {
  overflow: hidden;
}

.Logowide .elComp.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Logowide > .layoutFlow > .elComp {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Logowide > .layoutFlow > .elComp > * { 
    width: 100%;
  }
  


  .Logowide > .foreground > .elLogo {
    position: absolute;
    left: 0px;
    top: -2px;
    width: 216px;
    height: 66px;
  }


/* Narrowest size for this element's responsive layout */
  .ClientsScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .ClientsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .ClientsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .ClientsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .ClientsScreen > .layoutFlow > .elSwapper {
    position: relative;
    margin-top: 0px;
    margin-left: 7.5%;
    margin-right: 11.4%;
    width: calc(81.1% - 0px);
  }
  .ClientsScreen > .layoutFlow > .elSwapper > * { 
    width: 100%;
  }
  
  .ClientsScreen > .layoutFlow > .elSwapper > .background > .containerMinHeight {
    min-height: 77px;
  }

@media (min-width: 568px) {
  .ClientsScreen > .layoutFlow > .elSwapper {
    position: relative;
    margin-top: 0px;
    margin-left: 7.5%;
    margin-right: 11.4%;
    width: calc(81.1% - 0px);
  }
  .ClientsScreen > .layoutFlow > .elSwapper > * { 
    width: 100%;
  }
  
  .ClientsScreen > .layoutFlow > .elSwapper > .background > .containerMinHeight {
    min-height: 77px;
  }

}
@media (min-width: 768px) {
  .ClientsScreen > .layoutFlow > .elSwapper {
    position: relative;
    margin-top: 0px;
    margin-left: 7.0%;
    margin-right: 7.0%;
    width: calc(85.9% - 0px);
  }
  .ClientsScreen > .layoutFlow > .elSwapper > * { 
    width: 100%;
  }
  
  .ClientsScreen > .layoutFlow > .elSwapper > .background > .containerMinHeight {
    min-height: 77px;
  }

}
@media (min-width: 1024px) {
  .ClientsScreen > .layoutFlow > .elSwapper {
    position: relative;
    margin-top: 0px;
    margin-left: 5.3%;
    margin-right: 5.3%;
    width: calc(89.4% - 0px);
  }
  .ClientsScreen > .layoutFlow > .elSwapper > * { 
    width: 100%;
  }
  
  .ClientsScreen > .layoutFlow > .elSwapper > .background > .containerMinHeight {
    min-height: 77px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ClientsScreen > .layoutFlow > .elImage {
    position: relative;
    margin-top: -2px;
    margin-left: 7.5%;
    margin-right: 11.4%;
    width: calc(81.1% - 0px);
  }
  .ClientsScreen > .layoutFlow > .elImage > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .ClientsScreen > .layoutFlow > .elImage {
    position: relative;
    margin-top: -2px;
    margin-left: 7.5%;
    margin-right: 11.4%;
    width: calc(81.1% - 0px);
  }
  .ClientsScreen > .layoutFlow > .elImage > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .ClientsScreen > .layoutFlow > .elImage {
    position: relative;
    margin-top: -2px;
    margin-left: 7.0%;
    margin-right: 7.0%;
    width: calc(85.9% - 0px);
  }
  .ClientsScreen > .layoutFlow > .elImage > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .ClientsScreen > .layoutFlow > .elImage {
    position: relative;
    margin-top: -2px;
    margin-left: 5.3%;
    margin-right: 5.3%;
    width: calc(89.4% - 0px);
  }
  .ClientsScreen > .layoutFlow > .elImage > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ClientsScreen > .layoutFlow > .elText {
    position: relative;
    margin-top: 4px;
    margin-left: 7.5%;
    margin-right: 11.4%;
    width: calc(81.1% - 0px);
  }
  .ClientsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .ClientsScreen > .layoutFlow > .elText {
    position: relative;
    margin-top: 4px;
    margin-left: 7.5%;
    margin-right: 11.4%;
    width: calc(81.1% - 0px);
  }
  .ClientsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .ClientsScreen > .layoutFlow > .elText {
    position: relative;
    margin-top: 4px;
    margin-left: 7.0%;
    margin-right: 7.0%;
    width: calc(85.9% - 0px);
  }
  .ClientsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .ClientsScreen > .layoutFlow > .elText {
    position: relative;
    margin-top: 4px;
    margin-left: 5.3%;
    margin-right: 5.3%;
    width: calc(89.4% - 0px);
  }
  .ClientsScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}

.ClientsScreen .elFooter1 {
  overflow: hidden;
}

.ClientsScreen .elFooter1.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .ClientsScreen > .layoutFlow > .elFooter1 {
    position: relative;
    margin-top: 12px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ClientsScreen > .layoutFlow > .elFooter1 > * { 
    width: 100%;
  }
  
  .ClientsScreen > .layoutFlow > .elFooter1 > .background > .containerMinHeight {
    min-height: 28px;
  }

@media (min-width: 568px) {
  .ClientsScreen > .layoutFlow > .elFooter1 {
    position: relative;
    margin-top: 12px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ClientsScreen > .layoutFlow > .elFooter1 > * { 
    width: 100%;
  }
  
  .ClientsScreen > .layoutFlow > .elFooter1 > .background > .containerMinHeight {
    min-height: 28px;
  }

}
@media (min-width: 768px) {
  .ClientsScreen > .layoutFlow > .elFooter1 {
    position: relative;
    margin-top: 12px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ClientsScreen > .layoutFlow > .elFooter1 > * { 
    width: 100%;
  }
  
  .ClientsScreen > .layoutFlow > .elFooter1 > .background > .containerMinHeight {
    min-height: 28px;
  }

}
@media (min-width: 1024px) {
  .ClientsScreen > .layoutFlow > .elFooter1 {
    position: relative;
    margin-top: 12px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ClientsScreen > .layoutFlow > .elFooter1 > * { 
    width: 100%;
  }
  
  .ClientsScreen > .layoutFlow > .elFooter1 > .background > .containerMinHeight {
    min-height: 28px;
  }

}

.ClientsScreen .elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.ClientsScreen .elIconButton:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ClientsScreen > .screenFgContainer > .foreground > .elIconButton {
    position: absolute;
    right: calc(11.4% + 0px);
    top: 4px;
    width: 41px;
    height: 36px;
  }
@media (min-width: 568px) {
  .ClientsScreen > .screenFgContainer > .foreground > .elIconButton {
    right: calc(11.4% + 0px);
    top: 4px;
    width: 41px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .ClientsScreen > .screenFgContainer > .foreground > .elIconButton {
    right: calc(7.0% + 0px);
    top: 4px;
    width: 41px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .ClientsScreen > .screenFgContainer > .foreground > .elIconButton {
    right: calc(5.3% + 0px);
    top: 4px;
    width: 41px;
    height: 36px;
  }
}

.ClientsScreen .elDropMenu {
  overflow: hidden;
}

.ClientsScreen .elDropMenu.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .ClientsScreen > .screenFgContainer > .foreground > .elDropMenu {
    position: absolute;
    right: calc(11.4% + 0px);
    top: 6px;
    width: 78px;
    height: 108px;
  }
  .ClientsScreen > .screenFgContainer > .foreground > .elDropMenu > .background > .containerMinHeight {
    min-height: 107px;
  }

@media (min-width: 568px) {
  .ClientsScreen > .screenFgContainer > .foreground > .elDropMenu {
    right: calc(11.4% + 0px);
    top: 6px;
    width: 78px;
    height: 108px;
  }
  .ClientsScreen > .screenFgContainer > .foreground > .elDropMenu > .background > .containerMinHeight {
    min-height: 107px;
  }

}
@media (min-width: 768px) {
  .ClientsScreen > .screenFgContainer > .foreground > .elDropMenu {
    right: calc(7.0% + 0px);
    top: 6px;
    width: 78px;
    height: 108px;
  }
  .ClientsScreen > .screenFgContainer > .foreground > .elDropMenu > .background > .containerMinHeight {
    min-height: 106px;
  }

}
@media (min-width: 1024px) {
  .ClientsScreen > .screenFgContainer > .foreground > .elDropMenu {
    right: calc(5.3% + 0px);
    top: 6px;
    width: 78px;
    height: 108px;
  }
  .ClientsScreen > .screenFgContainer > .foreground > .elDropMenu > .background > .containerMinHeight {
    min-height: 106px;
  }

}


/* Narrowest size for this element's responsive layout */
  .GalleryScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .GalleryScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .GalleryScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .GalleryScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .GalleryScreen > .layoutFlow > .elSwapper {
    position: relative;
    margin-top: 0px;
    margin-left: 5.9%;
    margin-right: 7.2%;
    width: calc(86.9% - 0px);
  }
  .GalleryScreen > .layoutFlow > .elSwapper > * { 
    width: 100%;
  }
  
  .GalleryScreen > .layoutFlow > .elSwapper > .background > .containerMinHeight {
    min-height: 77px;
  }

@media (min-width: 568px) {
  .GalleryScreen > .layoutFlow > .elSwapper {
    position: relative;
    margin-top: 0px;
    margin-left: 5.9%;
    margin-right: 7.2%;
    width: calc(86.9% - 0px);
  }
  .GalleryScreen > .layoutFlow > .elSwapper > * { 
    width: 100%;
  }
  
  .GalleryScreen > .layoutFlow > .elSwapper > .background > .containerMinHeight {
    min-height: 77px;
  }

}
@media (min-width: 768px) {
  .GalleryScreen > .layoutFlow > .elSwapper {
    position: relative;
    margin-top: 0px;
    margin-left: -7.0%;
    margin-right: 7.0%;
    width: calc(100.0% - 0px);
  }
  .GalleryScreen > .layoutFlow > .elSwapper > * { 
    width: 100%;
  }
  
  .GalleryScreen > .layoutFlow > .elSwapper > .background > .containerMinHeight {
    min-height: 77px;
  }

}
@media (min-width: 1024px) {
  .GalleryScreen > .layoutFlow > .elSwapper {
    position: relative;
    margin-top: 0px;
    margin-left: 5.3%;
    margin-right: 5.3%;
    width: calc(89.4% - 0px);
  }
  .GalleryScreen > .layoutFlow > .elSwapper > * { 
    width: 100%;
  }
  
  .GalleryScreen > .layoutFlow > .elSwapper > .background > .containerMinHeight {
    min-height: 77px;
  }

}


/* Narrowest size for this element's responsive layout */
  .GalleryScreen > .layoutFlow > .elGallery {
    position: relative;
    margin-top: 50px;
    margin-left: 15.9%;
    margin-right: 17.2%;
    width: calc(66.9% - 0px);
  }
  .GalleryScreen > .layoutFlow > .elGallery > * { 
    width: 100%;
  height: 163px;
  }
  
@media (min-width: 568px) {
  .GalleryScreen > .layoutFlow > .elGallery {
    position: relative;
    margin-top: 50px;
    margin-left: 15.9%;
    margin-right: 17.2%;
    width: calc(66.9% - 0px);
  }
  .GalleryScreen > .layoutFlow > .elGallery > * { 
    width: 100%;
  height: 163px;
  }
  
}
@media (min-width: 768px) {
  .GalleryScreen > .layoutFlow > .elGallery {
    position: relative;
    margin-top: 50px;
    margin-left: calc(3.0% + 40px);
    margin-right: 17.0%;
    width: calc(80.0% - 40px);
  }
  .GalleryScreen > .layoutFlow > .elGallery > * { 
    width: 100%;
  height: 162px;
  }
  
}
@media (min-width: 1024px) {
  .GalleryScreen > .layoutFlow > .elGallery {
    position: relative;
    margin-top: 50px;
    margin-left: 15.3%;
    margin-right: 15.3%;
    width: calc(69.4% - 0px);
  }
  .GalleryScreen > .layoutFlow > .elGallery > * { 
    width: 100%;
  height: 162px;
  }
  
}

.GalleryScreen .elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.GalleryScreen .elIconButton:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .GalleryScreen > .screenFgContainer > .foreground > .elIconButton {
    position: absolute;
    right: calc(7.2% + 0px);
    top: 0px;
    width: 41px;
    height: 36px;
  }
@media (min-width: 568px) {
  .GalleryScreen > .screenFgContainer > .foreground > .elIconButton {
    right: calc(7.2% + 0px);
    top: 0px;
    width: 41px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .GalleryScreen > .screenFgContainer > .foreground > .elIconButton {
    right: calc(7.0% + 0px);
    top: 0px;
    width: 41px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .GalleryScreen > .screenFgContainer > .foreground > .elIconButton {
    right: calc(5.3% + 0px);
    top: 0px;
    width: 41px;
    height: 36px;
  }
}

.GalleryScreen .elDropMenu {
  overflow: hidden;
}

.GalleryScreen .elDropMenu.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .GalleryScreen > .screenFgContainer > .foreground > .elDropMenu {
    position: absolute;
    right: calc(7.2% + 0px);
    top: 0px;
    width: 90px;
    height: 111px;
  }
  .GalleryScreen > .screenFgContainer > .foreground > .elDropMenu > .background > .containerMinHeight {
    min-height: 107px;
  }

@media (min-width: 568px) {
  .GalleryScreen > .screenFgContainer > .foreground > .elDropMenu {
    right: calc(7.2% + 0px);
    top: 0px;
    width: 90px;
    height: 111px;
  }
  .GalleryScreen > .screenFgContainer > .foreground > .elDropMenu > .background > .containerMinHeight {
    min-height: 107px;
  }

}
@media (min-width: 768px) {
  .GalleryScreen > .screenFgContainer > .foreground > .elDropMenu {
    right: calc(7.0% + 0px);
    top: 0px;
    width: 90px;
    height: 110px;
  }
  .GalleryScreen > .screenFgContainer > .foreground > .elDropMenu > .background > .containerMinHeight {
    min-height: 106px;
  }

}
@media (min-width: 1024px) {
  .GalleryScreen > .screenFgContainer > .foreground > .elDropMenu {
    right: calc(5.3% + 0px);
    top: 0px;
    width: 90px;
    height: 110px;
  }
  .GalleryScreen > .screenFgContainer > .foreground > .elDropMenu > .background > .containerMinHeight {
    min-height: 106px;
  }

}

/* This component has a fixed-size layout */
  .WhoWeAre {
    min-height: 521px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 409px */
    height: 100%;
  }
  .WhoWeAre > .background > .containerMinHeight {
    min-height: 521px;
  }


  .WhoWeAre > .layoutFlow > .elImage {
    position: relative;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100.0% - 30px);
  }
  .WhoWeAre > .layoutFlow > .elImage > * { 
    width: 100%;
  height: 150px;
  }
  


  .WhoWeAre > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100.0% - 30px);
  }
  .WhoWeAre > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  


  .WhoWeAre > .layoutFlow > .elText {
    position: relative;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100.0% - 30px);
  }
  .WhoWeAre > .layoutFlow > .elText > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .WhoUseUs {
    min-height: 623px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 389px */
    height: 100%;
  }
  .WhoUseUs > .background > .containerMinHeight {
    min-height: 623px;
  }


  .WhoUseUs > .layoutFlow > .elImage {
    position: relative;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100.0% - 30px);
  }
  .WhoUseUs > .layoutFlow > .elImage > * { 
    width: 100%;
  height: 150px;
  }
  


  .WhoUseUs > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100.0% - 30px);
  }
  .WhoUseUs > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  


  .WhoUseUs > .layoutFlow > .elText {
    position: relative;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100.0% - 30px);
  }
  .WhoUseUs > .layoutFlow > .elText > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .Why3 {
    min-height: 383px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 424px */
    height: 100%;
  }
  .Why3 > .background > .containerMinHeight {
    min-height: 383px;
  }


  .Why3 > .layoutFlow > .elImage {
    position: relative;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100.0% - 30px);
  }
  .Why3 > .layoutFlow > .elImage > * { 
    width: 100%;
  height: 150px;
  }
  


  .Why3 > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100.0% - 30px);
  }
  .Why3 > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  


  .Why3 > .layoutFlow > .elText {
    position: relative;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100.0% - 30px);
  }
  .Why3 > .layoutFlow > .elText > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .DropMenu {
    min-height: 107px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 134px */
    height: 100%;
  }
  .DropMenu > .background > .containerMinHeight {
    min-height: 107px;
  }

.DropMenu .elHome button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.DropMenu .elHome button:focus {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2500);
  outline: none;
}



  .DropMenu > .layoutFlow > .elHome {
    position: relative;
    margin-top: 0px;
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100.0% - -10px);
  }
  .DropMenu > .layoutFlow > .elHome > * { 
    width: 100%;
  height: 35px;
  }
  

.DropMenu .elClients button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.DropMenu .elClients button:focus {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2500);
  outline: none;
}



  .DropMenu > .layoutFlow > .elClients {
    position: relative;
    margin-top: 0px;
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100.0% - -10px);
  }
  .DropMenu > .layoutFlow > .elClients > * { 
    width: 100%;
  height: 35px;
  }
  

.DropMenu .elGallery button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.DropMenu .elGallery button:focus {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2500);
  outline: none;
}



  .DropMenu > .layoutFlow > .elGallery {
    position: relative;
    margin-top: 0px;
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100.0% - -10px);
  }
  .DropMenu > .layoutFlow > .elGallery > * { 
    width: 100%;
  height: 35px;
  }
  

/* This component has a fixed-size layout */
  .ContactForm {
    min-height: 648px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 293px */
    height: 100%;
  }
  .ContactForm > .background > .containerMinHeight {
    min-height: 648px;
  }


  .ContactForm > .layoutFlow > .elImage {
    position: relative;
    margin-top: 15px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ContactForm > .layoutFlow > .elImage > * { 
    width: 100%;
  height: 202px;
  }
  


  .ContactForm > .layoutFlow > .elLabel {
    position: relative;
    margin-top: 9px;
    width: 222px;
    margin-left: 20px;
  }
  .ContactForm > .layoutFlow > .elLabel > * { 
  width: 222px;
  }
  


.ContactForm > .layoutFlow > .elName input {
  color: #ff2600;
}

.ContactForm > .layoutFlow > .elName input::input-placeholder {
  color: rgba(255, 54, 0, 0.5000);
}

.ContactForm > .layoutFlow > .elName input::placeholder {
  color: rgba(255, 54, 0, 0.5000);
}

  .ContactForm > .layoutFlow > .elName {
    position: relative;
    margin-top: 20px;
    width: 226px;
    margin-left: 20px;
  }
  .ContactForm > .layoutFlow > .elName > * { 
  width: 226px;
  height: 34px;
  }
  


.ContactForm > .layoutFlow > .elEmail input {
  color: #ff2600;
}

.ContactForm > .layoutFlow > .elEmail input::input-placeholder {
  color: rgba(255, 54, 0, 0.5000);
}

.ContactForm > .layoutFlow > .elEmail input::placeholder {
  color: rgba(255, 54, 0, 0.5000);
}

  .ContactForm > .layoutFlow > .elEmail {
    position: relative;
    margin-top: 0px;
    width: 226px;
    margin-left: 20px;
  }
  .ContactForm > .layoutFlow > .elEmail > * { 
  width: 226px;
  height: 34px;
  }
  


  .ContactForm > .layoutFlow > .elPhone {
    position: relative;
    margin-top: 0px;
    width: 226px;
    margin-left: 20px;
  }
  .ContactForm > .layoutFlow > .elPhone > * { 
  width: 226px;
  height: 34px;
  }
  


  .ContactForm > .layoutFlow > .elWebsite {
    position: relative;
    margin-top: 0px;
    width: 226px;
    margin-left: 20px;
  }
  .ContactForm > .layoutFlow > .elWebsite > * { 
  width: 226px;
  height: 34px;
  }
  


  .ContactForm > .layoutFlow > .elTextarea {
    position: relative;
    margin-top: 0px;
    width: 226px;
    margin-left: 20px;
  }
  .ContactForm > .layoutFlow > .elTextarea > * { 
  width: 226px;
  height: 78px;
  }
  

.ContactForm .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.ContactForm .elButton button:focus {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2500);
  outline: none;
}



  .ContactForm > .layoutFlow > .elButton {
    position: relative;
    margin-top: 18px;
    width: 145px;
    margin-left: 54px;
  }
  .ContactForm > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  


  .ContactForm > .layoutFlow > .elSendconfirmation {
    overflow: hidden;
    position: relative;
    margin-top: 18px;
    margin-left: 20px;
    margin-right: 54px;
    width: calc(100.0% - 74px);
  }
  .ContactForm > .layoutFlow > .elSendconfirmation > * { 
    width: 100%;
  }
  


  .ContactForm > .layoutFlow > .elValidator {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ContactForm > .layoutFlow > .elValidator > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .Footer1 {
    min-height: 28px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 675px */
    height: 100%;
  }
  .Footer1 > .background > .containerMinHeight {
    min-height: 28px;
  }


  .Footer1 > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Footer1 > .layoutFlow > .elText > * { 
    width: 100%;
  }
  


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elSwapper {
    position: relative;
    margin-top: 0px;
    margin-left: 5.0%;
    margin-right: 8.3%;
    width: calc(86.7% - 0px);
  }
  .HomeScreen > .layoutFlow > .elSwapper > * { 
    width: 100%;
  }
  
  .HomeScreen > .layoutFlow > .elSwapper > .background > .containerMinHeight {
    min-height: 77px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elSwapper {
    position: relative;
    margin-top: 0px;
    margin-left: 5.0%;
    margin-right: 8.3%;
    width: calc(86.7% - 0px);
  }
  .HomeScreen > .layoutFlow > .elSwapper > * { 
    width: 100%;
  }
  
  .HomeScreen > .layoutFlow > .elSwapper > .background > .containerMinHeight {
    min-height: 77px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elSwapper {
    position: relative;
    margin-top: 0px;
    margin-left: 5.0%;
    margin-right: 8.3%;
    width: calc(86.7% - 0px);
  }
  .HomeScreen > .layoutFlow > .elSwapper > * { 
    width: 100%;
  }
  
  .HomeScreen > .layoutFlow > .elSwapper > .background > .containerMinHeight {
    min-height: 77px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elSwapper {
    position: relative;
    margin-top: 0px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .HomeScreen > .layoutFlow > .elSwapper > * { 
    width: 100%;
  }
  
  .HomeScreen > .layoutFlow > .elSwapper > .background > .containerMinHeight {
    min-height: 77px;
  }

}

  .HomeScreen .elColumns > div {
    display: flex;
    flex-direction: row;
    height: auto;  /* This element is in scroll flow */
    flex-wrap: wrap;
  }

  .HomeScreen .elColumns .col0 {
    flex: 50.0;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .HomeScreen .elColumns .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
  }

}

@media (max-width: 1023px) {
  .HomeScreen .elColumns .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
  }

}

@media (max-width: 767px) {
  .HomeScreen .elColumns .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 767px;
  }

}

@media (max-width: 567px) {
  .HomeScreen .elColumns .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 567px;
  }

}

  .HomeScreen .elColumns .col1 {
    flex: 50.0;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .HomeScreen .elColumns .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
  }

}

@media (max-width: 1023px) {
  .HomeScreen .elColumns .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
  }

}

@media (max-width: 767px) {
  .HomeScreen .elColumns .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 767px;
  }

}

@media (max-width: 567px) {
  .HomeScreen .elColumns .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 567px;
  }

}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elColumns {
    position: relative;
    margin-top: 0px;
    margin-left: 5.0%;
    margin-right: 8.3%;
    width: calc(86.7% - 0px);
  }
  .HomeScreen > .layoutFlow > .elColumns > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elColumns {
    position: relative;
    margin-top: 0px;
    margin-left: 5.0%;
    margin-right: 8.3%;
    width: calc(86.7% - 0px);
  }
  .HomeScreen > .layoutFlow > .elColumns > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elColumns {
    position: relative;
    margin-top: 0px;
    margin-left: 5.0%;
    margin-right: 8.3%;
    width: calc(86.7% - 0px);
  }
  .HomeScreen > .layoutFlow > .elColumns > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elColumns {
    position: relative;
    margin-top: 0px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .HomeScreen > .layoutFlow > .elColumns > * { 
    width: 100%;
  }
  
}

  .HomeScreen .elColumns2 > div {
    display: flex;
    flex-direction: row;
    height: auto;  /* This element is in scroll flow */
    flex-wrap: wrap;
  }

  .HomeScreen .elColumns2 .col0 {
    flex: 50.0;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .HomeScreen .elColumns2 .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
  }

}

@media (max-width: 1023px) {
  .HomeScreen .elColumns2 .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
  }

}

@media (max-width: 767px) {
  .HomeScreen .elColumns2 .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 767px;
  }

}

@media (max-width: 567px) {
  .HomeScreen .elColumns2 .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 567px;
  }

}

  .HomeScreen .elColumns2 .col1 {
    flex: 50.0;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .HomeScreen .elColumns2 .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
  }

}

@media (max-width: 1023px) {
  .HomeScreen .elColumns2 .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
  }

}

@media (max-width: 767px) {
  .HomeScreen .elColumns2 .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 767px;
  }

}

@media (max-width: 567px) {
  .HomeScreen .elColumns2 .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 567px;
  }

}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elColumns2 {
    position: relative;
    margin-top: 0px;
    margin-left: 5.0%;
    margin-right: 8.3%;
    width: calc(86.7% - 0px);
  }
  .HomeScreen > .layoutFlow > .elColumns2 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elColumns2 {
    position: relative;
    margin-top: 0px;
    margin-left: 5.0%;
    margin-right: 8.3%;
    width: calc(86.7% - 0px);
  }
  .HomeScreen > .layoutFlow > .elColumns2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elColumns2 {
    position: relative;
    margin-top: 0px;
    margin-left: 5.0%;
    margin-right: 8.3%;
    width: calc(86.7% - 0px);
  }
  .HomeScreen > .layoutFlow > .elColumns2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elColumns2 {
    position: relative;
    margin-top: 0px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .HomeScreen > .layoutFlow > .elColumns2 > * { 
    width: 100%;
  }
  
}

.HomeScreen .elFooter1 {
  overflow: hidden;
}

.HomeScreen .elFooter1.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elFooter1 {
    position: relative;
    margin-top: 16px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elFooter1 > * { 
    width: 100%;
  }
  
  .HomeScreen > .layoutFlow > .elFooter1 > .background > .containerMinHeight {
    min-height: 28px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elFooter1 {
    position: relative;
    margin-top: 16px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elFooter1 > * { 
    width: 100%;
  }
  
  .HomeScreen > .layoutFlow > .elFooter1 > .background > .containerMinHeight {
    min-height: 28px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elFooter1 {
    position: relative;
    margin-top: 16px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elFooter1 > * { 
    width: 100%;
  }
  
  .HomeScreen > .layoutFlow > .elFooter1 > .background > .containerMinHeight {
    min-height: 28px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elFooter1 {
    position: relative;
    margin-top: 16px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elFooter1 > * { 
    width: 100%;
  }
  
  .HomeScreen > .layoutFlow > .elFooter1 > .background > .containerMinHeight {
    min-height: 28px;
  }

}

.HomeScreen .elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.HomeScreen .elIconButton:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elIconButton {
    position: absolute;
    right: calc(8.3% + 0px);
    top: 9px;
    width: 41px;
    height: 36px;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elIconButton {
    right: calc(8.3% + 0px);
    top: 9px;
    width: 41px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elIconButton {
    right: calc(8.3% + 0px);
    top: 9px;
    width: 41px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elIconButton {
    right: calc(9.4% + 0px);
    top: 9px;
    width: 41px;
    height: 36px;
  }
}

.HomeScreen .elDropMenu {
  overflow: hidden;
}

.HomeScreen .elDropMenu.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elDropMenu {
    position: absolute;
    left: calc(91.7% + -150px);
    right: calc(8.3% + 0px);
    top: 12px;
    width: calc(0.0% - -150px - 0px);
    height: 106px;
  }
  .HomeScreen > .screenFgContainer > .foreground > .elDropMenu > .background > .containerMinHeight {
    min-height: 107px;
  }

@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elDropMenu {
    left: calc(91.7% + -150px);
    right: calc(8.3% + 0px);
    top: 12px;
    width: calc(0.0% - -150px - 0px);
    height: 106px;
  }
  .HomeScreen > .screenFgContainer > .foreground > .elDropMenu > .background > .containerMinHeight {
    min-height: 107px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elDropMenu {
    left: calc(91.7% + -149px);
    right: calc(8.3% + 0px);
    top: 12px;
    width: calc(0.0% - -149px - 0px);
    height: 106px;
  }
  .HomeScreen > .screenFgContainer > .foreground > .elDropMenu > .background > .containerMinHeight {
    min-height: 106px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elDropMenu {
    left: calc(90.6% + -149px);
    right: calc(9.4% + 0px);
    top: 12px;
    width: calc(0.0% - -149px - 0px);
    height: 106px;
  }
  .HomeScreen > .screenFgContainer > .foreground > .elDropMenu > .background > .containerMinHeight {
    min-height: 106px;
  }

}

/* This component has a fixed-size layout */
  .GalleryItem {
    min-height: 381px;  /* Minimum height to ensure layout flow is visible */
    min-width: 182px; /* This component has a fixed size */
  }
  .GalleryItem > .background > .containerMinHeight {
    min-height: 381px;
  }


  .GalleryItem > .layoutFlow > .elImage {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .GalleryItem > .layoutFlow > .elImage > * { 
    width: 100%;
  height: 77px;
  }
  


  .GalleryItem > .layoutFlow > .elEmbed {
    position: relative;
    margin-top: 2px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .GalleryItem > .layoutFlow > .elEmbed > * { 
    width: 100%;
  }
  

.GalleryItem .elFooter1 {
  overflow: hidden;
}

.GalleryItem .elFooter1.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .GalleryItem > .layoutFlow > .elFooter1 {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .GalleryItem > .layoutFlow > .elFooter1 > * { 
    width: 100%;
  }
  

